html,
body {
    padding: 0;
    margin: 0;
}

.App {
    font-family: sans-serif;
    display: flex;
    flex-direction: row;
}

.Map {
    width: 100vw;
    height: 100vh;
}

.pbsg-region {
    background: none;
    border: none;
    box-shadow: none;
    color: black;
    /* or whatever color you desire for the text */
    font-size: 12px;
    font-weight: bold;
}

.leaflet-container {
    background: #f7f4f0;
}